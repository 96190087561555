import React from "react"
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import Vimeo from '@u-wave/react-vimeo';

const Story = () => {

    return (
        <Layout>
            <Seo title='Our Story' />
            <section className="story-section">
                <div class="story-container">
                    <h1 className="uppercase">Our Story</h1>
                </div>
		<div className="container">
		<p className="story-paragraph">Scientific Financial Systems, headquartered in Boston, MA, is an entrepreneurial team of quantitative investment practitioners and data scientists motivated to 
		improve state-of-the-art in investment technology.  SFS’s flagship product, Quotient™, provides an enterprise solution for investment managers that provides a 
		data-driven view of investment opportunities and strategies, along with more control and greater flexibility. Quotient™ enables financial institutions to analyze 
		rapidly large amounts of data and provide data-driven results and recommendations. In addition, Quotient™ partners with and integrates with some of the largest global 
		financial data providers and provides an advanced analytical engine for data manipulation, factor building, back-testing, and portfolio construction.</p>
		<div className="story-video__vid">
                    <Vimeo
                        video="657099670"
                        responsive
                        width="400"
                        showByline={false}
                        showPortrait={false}
                        showTitle={false}
                     />
                </div>		
		</div>
            </section>
        </Layout>
    )
}

export default Story;
